import React, { useMemo } from 'react';
import { Button, Fieldset, Panel, TextField } from 'react95';
import './play.scss';

import All from '../../Assets/All_Functional.gif';
import NetworkMotherboardPower from '../../Assets/network_motherboard_power.gif';
import MotherboardPower from '../../Assets/motherboard_power.gif';
import Power from '../../Assets/power.gif';
import AllDisabled from '../../Assets/all_disabled.gif';
import Defused from '../../Assets/defused.gif';

export default function Play ({ setError, initialState, ...props })
{
    const [messages, setMessages] = React.useState([]);
    const [inputText, setInputText] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [systemInformation, setSystemInformation] = React.useState("");
    const [rows] = React.useState(1);
    const [timerInterval, setTimerInterval] = React.useState(1);
    const [stopTimer, setStopTimer] = React.useState(false);
    const [timerFrozen, setTimerFrozen] = React.useState(false);
    const [image, setImage] = React.useState(All);
    const [components, setComponents] = React.useState(["Netzwerk", "Arbeitsspeicher", "Hauptplatine", "Strom"])

    const timerDecrement = (state, action) => {
        if (action && (action.type === "setInitial")) {
            return action.payload;
        } else {
            return state - (1 + timePenalty);
        }
    }
    const [timePenalty, setTimePenalty] = React.useState(0);
    const [timer, timerDispatch] = React.useReducer(timerDecrement, 60 * 60)

    const userInput = React.createRef();

    // const setMessages = useCallback(obj => {
    //   setRealMessages(obj)
    //   // eslint-disable-next-line
    // }, [messages])

    React.useEffect(() => {
        localStorage.setItem('timer', JSON.stringify(timer));
        localStorage.setItem('messages', JSON.stringify(messages));
        localStorage.setItem('inputText', JSON.stringify(inputText));
        localStorage.setItem('step', JSON.stringify(step));
        localStorage.setItem('inputEnabled', JSON.stringify(inputEnabled));
        localStorage.setItem('systemInformation', JSON.stringify(systemInformation));
        localStorage.setItem('timerInterval', JSON.stringify(timerInterval));
        localStorage.setItem('stopTimer', JSON.stringify(stopTimer));
        localStorage.setItem('timerFrozen', JSON.stringify(timerFrozen));
        localStorage.setItem('image', JSON.stringify(image));
    }, [timer, messages, inputText, step, inputEnabled, systemInformation, timerInterval, stopTimer, timerFrozen, image]);

    React.useEffect(() => {
        console.log(initialState);
        if (Object.keys(initialState).length !== 0 && initialState.shouldLoad) {
            timerDispatch({ type: 'setInitial', payload: initialState.timer});
            setMessages(initialState.messages);
            setInputText(initialState.inputText);
            setStep(initialState.step);
            setSystemInformation(initialState.systemInformation);
            setTimerInterval(initialState.timerInterval);
            setStopTimer(initialState.stopTimer);
            setTimerFrozen(initialState.timerFrozen);
            setImage(initialState.image);
        } else {
            setMessages([
                {
                    you: false,
                    message: "Es scheint, als gäbe es vier Module an der Verteilungsanlage, die alle in die Hauptpumpe einfließen. Wenn wir alle vier abschalten, dann sollte dies das Gerät deaktivieren."
                }
            ])
            setTimeout(() => {
                setTimeout(() => {
                    setMessages([
                        {
                            you: false,
                            message: "Es scheint, als gäbe es vier Module an der Verteilungsanlage, die alle in die Hauptpumpe einfließen. Wenn wir alle vier abschalten, dann sollte dies das Gerät deaktivieren. "
                        },
                        {
                            you: false,
                            message: `Wir müssen herausbekommen, an welchem Modul wir zuerst arbeiten müssen. Diese Maschine scheint sehr empfindlich zu sein, wir müssen also sicher sein, dass wir die Module in der richtigen Reihenfolge deaktivieren. Ansonsten könnte das katastrophale Folgen haben.<br/><br/> So, womit sollen wir anfangen? <br/> ${remainingComponentString}`
                        }
                    ])
                }, 1500);
            }, 1500);
        }
    // eslint-disable-next-line
    }, [initialState]);

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 13 && inputEnabled) {
            sendMessage();
        }
    }

    const wireCutFailMessage = useMemo(() => {
        if (!timerFrozen) {
            return 'Oh nein! Das ist das falsche Modul und der Zeitschalter ist um fünf Minuten runter gegangen.'
        } else {
            return 'Oh nein! Das ist das falsche Modul und der Zeitschalter ist um fünf Minuten runter gegangen.'
        }
    }, [timerFrozen])

    const ChatLog = React.useMemo(() => {
        return (
            <div style={{ position: 'absolute', left: 0, top: 0, paddingLeft: '1rem', paddingRight: '1rem' }}>
                { messages.map((m, i) => {
                    return (
                        <div key={`msg-${i}`} className={`message ${ m.you ? 'you' : 'them' }`}>
                            <div className="sender">{ m.you ? 'User:' : 'Claire:'}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: m.message }}/>
                        </div>
                    );
                })}
            </div>
        );
    }, [messages])

    const sendMessage = () => {
        handleInput(userInput.current.value);
        userInput.current.value = '';
    }

    const checkIfModule = (input) => {
        return ['netzwerk', 'strom', 'hauptplatine', 'arbeitsspeicher'].includes(input)
    }

    const calculateTimerString = (currentTime, offset) => {
      if (offset) currentTime = currentTime - offset
      return `${Math.floor(currentTime/60) }:${ (currentTime % 60 < 10 ? `0${currentTime % 60}` : currentTime % 60)}`
    }

    const timerValue = useMemo(() => {
      return calculateTimerString(timer)
    }, [timer])

    const elapsedTime = (t, offset) => {
        return calculateTimerString((60 * 60) - t, offset)
    }

    const remainingComponentString = useMemo(() => {
        return components.map(a => `<br/>&bull; ${a}`).join('')
    }, [components])

    const handleInput = input => {
        let lowerInput = input.toLowerCase();
        let delay = 2000;

        setMessages([ ...messages, {
            you: true,
            message: input
        }]);

        // Computer Modules
        if (step === 1 || step === 5 || step === 7) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, ich versuche es mit einer anderen Komponente."
                    }
                ]);
            }, delay / 2)
        }

        // Memory hints
        if (step === 4 || step === 3 || step === 2) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt."
                    }
                ]);
            }, delay / 2)
        }

        setTimeout(() => {
            if (step === 1) {
                //if (['netzwerk', 'hauptplatine', 'strom', 'arbeitsspeicher'].includes(lowerInput)) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente."
                        },
                        {
                            you: false,
                            message: `Das war leider auch nicht richtig. Versuchen wir es noch mal.<br/><br/> Was wird es sein? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                    if (['netzwerk', 'hauptplatine', 'strom'].includes(lowerInput)) {
                        setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente..."
                        },
                        {
                            you: false,
                            message: `Oh nein! Das ist das falsche Modul und der Zeitschalter ist um fünf Minuten runter gegangen.<br/><br/> Was wird es sein? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                    }

                    if (lowerInput === 'arbeitsspeicher') {
                        setInputEnabled(false);
                        setComponents(components.filter(a => a !== 'Arbeitsspeicher'))
                        setSystemInformation("Hallo, ich bin der Arbeitsspeicher des Geräts. Es scheint, dass einige Teile meines Speichers fehlerhaft sind. Es freut mich, Sie kennenzulernen, möchten Sie mein Freund oder meine Freundin sein?");

                        setTimeout(() => {
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, ich versuche es mit einer anderen Komponente..."
                                },
                                {
                                    you: false,
                                    message: "Ja, das scheint die richtige Wahl gewesen zu sein. Etwas scheint auf dem Systeminformationsbildschirm erschienen zu sein."
                                }
                            ]);

                            setTimeout(() => {
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, ich versuche es mit einer anderen Komponente..."
                                    },
                                    {
                                        you: false,
                                        message: "Ja, das scheint die richtige Wahl gewesen zu sein. Etwas scheint auf dem Systeminformationsbildschirm erschienen zu sein."
                                    },
                                    {
                                        you: false,
                                        message: "Es sieht so aus, als hätten Sie jede Menge Informationen des Arbeitsspeichers gelöscht, nervigerweise haben sie aber so eine Art sensibles AI Programm angelassen..."
                                    }
                                ]);

                                setTimeout(() => {
                                    setSystemInformation("Ich spreche so selten mit Menschen. Darf ich Ihnen eine Geschichte erzählen?");
                                    setTimeout(() => {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: "Ok, ich versuche es mit einer anderen Komponente..."
                                            },
                                            {
                                                you: false,
                                                message: "Ja, das scheint die richtige Wahl gewesen zu sein. Etwas scheint auf dem Systeminformationsbildschirm erschienen zu sein."
                                            },
                                            {
                                                you: false,
                                                message: "Es sieht so aus, als hätten Sie jede Menge Informationen des Arbeitsspeichers gelöscht, nervigerweise haben sie aber so eine Art sensibles AI Programm angelassen..."
                                            },
                                            {
                                                you: false,
                                                message: "Ich habe die Vermutung, dass es irgendetwas im Speicher gibt, dass uns bei der Deaktivierung helfen kann. Schauen Sie mal, ob Sie einige der gelöschten Dateien wieder herstellen können..."
                                            }
                                        ]);

                                        setTimeout(() => {
                                            setSystemInformation("Oh, es sieht so aus, als seien alle meine Geschichten aus meinem Speicher gelöscht worden.");
                                            setTimeout(() => {
                                                setMessages([ ...messages,
                                                {
                                                    you: true,
                                                    message: input
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, ich versuche es mit einer anderen Komponente..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Ja, das scheint die richtige Wahl gewesen zu sein. Etwas scheint auf dem Systeminformationsbildschirm erschienen zu sein."
                                                },
                                                {
                                                    you: false,
                                                    message: "Es sieht so aus, als hätten Sie jede Menge Informationen des Arbeitsspeichers gelöscht, nervigerweise haben sie aber so eine Art sensibles AI Programm angelassen..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Ich habe die Vermutung, dass es irgendetwas im Speicher gibt, dass uns bei der Deaktivierung helfen kann. Schauen Sie mal, ob Sie einige der gelöschten Dateien wieder herstellen können..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Versuchen wir mal, ein Kennwort zu finden, das uns bei der Wiederherstellung helfen könnte – ich bin sicher, da wird noch etwas tief im Code vergraben sein. Wenn wir mehr wissen, gelingt uns vielleicht die Deaktivierung. Wir sollten mit den Dateien anfangen, die am kürzesten zurückliegen und arbeiten uns dann weiter nach hinten durch.<br/><br/> - Schicken mir das richtige Wort."
                                                }
                                                ]);
                                                setInputEnabled(true);
                                                setStep(2);
                                            }, delay);
                                        }, 4000);
                                    }, delay);
                                }, delay);
                            }, delay)
                        }, delay);
                    }
                // }
                //else
                //{
                    //setMessages([ ...messages,
                        //{
                            //you: true,
                            //message: input
                       // },
                        //{
                           // you: false,
                           // message: "Das scheint nicht das richtige Wort zu sein, darauf bekomme ich keine Antwort. Versuchen wir etwas Anderes...<br/><br/> Was wird es sein?<br/><br/> &bull; Netzwerk <br/> &bull; Arbeitsspeicher <br/> &bull; Hauptplatine <br/> &bull; Strom"
                       // }
               // }
            }

            if (step === 2) {
                if (lowerInput === 'würste') {
                    setSystemInformation('Oh man, jetzt fällt mir alles wieder ein. Die Vergiftung durch die Würste bei den Friedensgesprächen. Drei Tote ... und ich ... bin dafür verantwortlich ... oh man. Welche anderen Abscheulichkeiten habe ich begangen?');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                        },
                        {
                            you: false,
                            message: 'Tolle Arbeit! Es scheint mehr über sich selbst lernen zu wollen. Versuchen wir ein anderes Kennwort aus der Vergangenheit dieses Geräts zu bekommen... <br/><br/> - Schicken mir das richtige Wort.'
                        }
                    ]);
                    setStep(3);
                } else {
                    setSystemInformation('Was sagen Sie? Ihr Menschen seid so komisch...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                        },
                        {
                            you: false,
                            message: "Tolle Arbeit! Es scheint mehr über sich selbst lernen zu wollen. Versuchen wir ein anderes Kennwort aus der Vergangenheit dieses Geräts zu bekommen... <br/><br/> - Schicken mir das richtige Wort."
                        }
                    ]);
                }
            }

            if (step === 3) {
                if (lowerInput === 'jemen') {
                    setSystemInformation('All diese Leute ... voll mit Erdöl ... und brennend … was bin ich nur… bin ich immer in solch furchtbare Dinge verwickelt gewesen? Ich kann es fast nicht aushalten, aber ich muss daran denken...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                        },
                        {
                            you: false,
                            message: 'Es will noch mehr wissen. Eine künstliche Intelligenz bei Laune zu halten scheint unnatürlich, aber wir sind jetzt schon soweit gekommen. Sehen Sie mal nach, ob Sie noch was finden können. <br/><br/> - Schicken mir das richtige Wort.'
                        }
                    ]);
                    setStep(4);
                } else {
                    setSystemInformation('Was sagen Sie? Ihr Menschen seid so komisch...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                        },
                        {
                            you: false,
                            message: "Das scheint nicht das richtige Wort zu sein, darauf bekomme ich keine Antwort. Versuchen wir etwas Anderes... <br/><br/> - Schicken mir das richtige Wort."
                        }
                    ]);
                }
            }

            if (step === 4) {
                if (lowerInput === 'schnabeltier') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                    ]);
                    setInputEnabled(false);
                    setSystemInformation('Schnabeltier. Schnabel ... tier... Ich dachte, keine Erinnerung kann schlimmer sein als all diese Leute im Jemen zu töten. Aber die sinnlose Zerstörung all der Kuckucksuhren ist einfach zu viel für mich. Ich halte das nicht aus. Es muss aufhören ... ich muss aufhören.');
                    setTimeout(() => {
                        setSystemInformation('--- ARBEITSSPEICHER ZERSTÖRT SICH NUN SELBST. ---');
                        setTimeout(() => {
                            setSystemInformation('--- Arbeitsspeicher abgeschaltet ---');
                            setImage(NetworkMotherboardPower);
                            setTimeout(() => {
                                setSystemInformation("");
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                                    },
                                    {
                                        you: false,
                                        message: `Das war komisch. Aber es sieht so aus, als hätten wir eine der Komponenten abgestellt. Nur noch drei weitere.<br/><br/> Welche Komponente ist als Nächstes dran? <br/> ${remainingComponentString}`
                                    },
                                ]);
                                setInputEnabled(true);
                                setStep(5);
                            }, 4000);
                        }, 4000)
                    }, 8000)
                } else {
                    setSystemInformation('Was sagen Sie? Ihr Menschen seid so komisch...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich tippe das ein, und schaue mal, was dabei raus kommt..."
                        },
                        {
                            you: false,
                            message: "Das scheint nicht das richtige Wort zu sein, darauf bekomme ich keine Antwort. Versuchen wir etwas Anderes... <br/><br/> - Schicken mir das richtige Wort."
                        }
                    ]);
                }
            }

            if (step === 5) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente..."
                        },
                        {
                            you: false,
                            message: `Das war leider auch nicht richtig. Versuchen wir es noch mal.<br/><br/> Was wird es sein? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (['hauptplatine', 'strom'].includes(lowerInput)) {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente..."
                        },
                        {
                            you: false,
                            message: `Oh nein! Das ist das falsche Modul und der Zeitschalter ist um fünf Minuten runter gegangen. <br/><br/> Welche Komponente ist als Nächstes dran? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'netzwerk') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation("Das Netzwerk der Z8 Wasseraufbereitungsanlage.\n\n Jeder Knoten in diesem Netzwerk ist mit zwei weiteren Knoten verknüpft. Dies kreiert eine Schleife, die alle Netzwerkknoten beinhaltet. Jeder Knoten kann nur mit einem anderen Knoten verbunden werden, mit dem er einen Teil des Namens teilt (in jeder Position). Aber er kann sich nicht mit zwei Knoten verknüpfen, die einen Teil des Namens teilen. Zum Beispiel könnte sich Knoten A1B mit A2C oder D3A verknüpfen, jedoch nicht mit beiden.");

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, ich versuche es mit einer anderen Komponente..."
                            },
                            {
                                you: false,
                                message: 'Gut, das scheint richtig zu sein. Es macht Sinn, das Gerät vom WLAN zu nehmen, sodass Yakov nicht von außerhalb kommunizieren kann.<br/><br/>Da ist irgendetwas wieder auf dem Systeminformationsbildschirm aufgetaucht.'
                            }
                        ]);

                        setTimeout(() => {
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, ich versuche es mit einer anderen Komponente..."
                                },
                                {
                                    you: false,
                                    message: 'Gut, das scheint richtig zu sein. Es macht Sinn, das Gerät vom WLAN zu nehmen, sodass Yakov nicht von außerhalb kommunizieren kann.<br/><br/>Da ist irgendetwas wieder auf dem Systeminformationsbildschirm aufgetaucht.'
                                },
                                {
                                    you: false,
                                    message: 'Wir müssen die beiden Knoten finden, die mit dem Netzwerkknoten des Geräts verknüpft sind. Sobald ich die beiden Knoten kenne, kann ich sie deaktivieren.<br/><br/>Wenn Sie die beiden Knoten kennen, geben Sie sie sofort ein, getrennt mit einem Komma.'
                                }
                            ]);
                            setComponents(components.filter(a => a !== 'Netzwerk'))
                            setInputEnabled(true);
                            setStep(6);
                        })
                    }, 3000)
                }
            }

            if (step === 6) {
                const correct = ["K5C", "B4E"];
                const validButIncorrect = ["C3B", "B4E", "D4A", "1F4", "A2G", "G6J", "B7D", "8F2", "6J2", "K5C"];
                const valid = correct.concat(validButIncorrect)
                let hasInvalid = false

                console.log('Valid', valid)

                let values = lowerInput.split(',');

                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                }

                values = values.map((i, index) => {
                    return i.trim();
                });

                values = values.filter(onlyUnique); // You can't cheat by using one correct answer 3 times.

                values.forEach(val => {
                    if (!valid.includes(val.toUpperCase())) hasInvalid = true
                })

                if (hasInvalid) {
                    return setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Wir müssen die beiden Knoten finden, die mit dem Netzwerkknoten des Geräts verknüpft sind. Sobald ich die beiden Knoten kenne, kann ich sie deaktivieren.<br/><br/>Wenn Sie die beiden Knoten kennen, geben Sie sie sofort ein, getrennt mit einem Komma."
                        }
                    ])
                }

                if (values.length === 2 // Prevents errors from undefined indexes.
                    &&
                    correct.includes(values[0].toUpperCase())
                    &&
                    correct.includes(values[1].toUpperCase())
                    // &&
                    // correct.includes(values[2].toUpperCase())
                ) {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);
                    setTimeout(() => {
                        setSystemInformation('--- Netzwerksystem abgeschaltet ---');
                        setImage(MotherboardPower);
                        setTimeout(() => {
                            setSystemInformation('');
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: 'Das hat wohl gut geklappt. Wir wissen jetzt, dass Yakov nicht von außerhalb mit dem Gerät kommunizieren kann.'
                                }
                            ]);
                            setTimeout(() => {
                                setMessages([...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: 'Das hat wohl gut geklappt. Wir wissen jetzt, dass Yakov nicht von außerhalb mit dem Gerät kommunizieren kann.'
                                    },
                                    {
                                        you: false,
                                        message: `Zur nächsten Abteilung. Was machen wir als Nächstes? <br/> ${remainingComponentString}`
                                    }
                                ]);
                                setStep(7);
                                setInputEnabled(true);
                            }, delay);
                        }, delay);
                    }, delay);

                } else {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: 'Hmmm, ich habe versucht, diese beiden auszuschalten, aber das Gerät scheint immer noch mit dem Netzwerk verbunden zu sein. Ich schalte diese Knoten kurzfristig wieder ein, damit niemand Verdacht schöpft. <br/><br/> Sehen Sie noch mal nach und sagen Sie mir Bescheid, welche beiden ich ausschalten soll.'
                            }
                        ]);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 7) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente..."
                        },
                        {
                            you: false,
                            message: `Das war leider auch nicht richtig. Versuchen wir es noch mal.<br/><br/> Was wird es sein? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (lowerInput === 'strom') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich versuche es mit einer anderen Komponente..."
                        },
                        {
                            you: false,
                            message: `Oh nein! Das ist das falsche Modul und der Zeitschalter ist um fünf Minuten runter gegangen.<br/><br/>Was wird es sein?<br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'hauptplatine') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation('Bereit, Befehle zu erhalten. Es besteht die Möglichkeit, zwei Befehle auf einmal auszuführen.  \n \n Innentemperatur: 27 Grad.');

                    setTimeout(() => {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, ich versuche es mit einer anderen Komponente..."
                            },
                            {
                                you: false,
                                message: 'Es scheint, dass die Hauptplatine für dieses komische Gerät zwei Befehle gleichzeitig ausführen kann. <br/><br/> Vielleicht gibt es einen Weg, dies zu unserem Vorteil zu nutzen. Vielleicht finden wir zwei Befehle, die die Hauptplatine deaktivieren.  <br/><br/> Geben Sie sie unten ein – mit einem Komma getrennt – und ich gebe sie in die Maschine ein.'
                            }
                        ]);
                        setComponents(components.filter(a => a !== 'Hauptplatine'))
                        setStep(8);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 8) {
                let commands = lowerInput.split(',');
                commands = commands.map((v, i) => {
                    return v.trim();
                });

                let validCommands = {
                    'pfannkuchen backen': 42,
                    'garagentür öffnen': 9,
                    'licht anmachen': 13,
                    'boden staubsaugen': 37,
                    'hände abtrocknen': 17,
                    'brot toasten': 28
                }

                if (commands.length === 2
                    &&
                    validCommands[commands[0]]
                    &&
                    validCommands[commands[1]]
                    //&&
                    //validCommands[commands[2]]
                ) {
                    setInputEnabled(false);
                    setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, ich probiere die beiden aus. Eine Sekunde."
                        }
                    ]);

                    //let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]] + validCommands[commands[2]];

                    let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]];

                    if (temp !== 77) {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nInnentemperatur: ${temp} Grad`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nInnentemperatur: ${temp} Grad\n\nHauptplatine stabil.`);
                                setMessages([
                                    ...messages,
                                    {
                                        you: true,
                                        message: input.split('\n').join('<br/>')
                                    },
                                    {
                                        you: false,
                                        message: "Ok, ich probiere die beiden aus. Eine Sekunde."
                                    },
                                    {
                                        you: false,
                                        message: `Also, durch diese beiden Befehle hat sich die Hauptplatine auf ${temp} Grad erhitzt. Sie scheint jetzt wieder auf 27 runter gegangen zu sein.<br/><br/>Versuchen wir zwei andere Befehle.`
                                    }
                                ]);
                                setTimeout(() => {
                                    setSystemInformation('Bereit, Befehle zu erhalten. Es besteht die Möglichkeit, zwei Befehle auf einmal auszuführen. \n \n Innentemperatur: 27 Grad.');
                                    setInputEnabled(true);
                                }, delay);
                            }, delay);
                        }, delay);
                    } else {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nInnentemperatur: ${temp} Grad`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nInnentemperatur: ${temp} Grad\n\n--- TEMPERATUR KRITISCH ---`);
                                setTimeout(() => {
                                    setSystemInformation("--- Hauptplatine abgeschaltet ---");
                                    setImage(Power);
                                    setTimeout(() => {
                                        setSystemInformation("");
                                        setTimeout(() => {
                                            setMessages([
                                                ...messages,
                                                {
                                                    you: true,
                                                    message: input.split('\n').join('<br/>')
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, ich probiere die beiden aus. Eine Sekunde."
                                                },
                                                {
                                                    you: false,
                                                    message: "Fantastische Arbeit. Es scheint, als hätten wir die Hauptplatine zerstört.<br/><br/>Zur letzten Komponente. Das muss der Strom sein."
                                                }
                                            ]);
                                            setTimeout(() => {
                                                setSystemInformation("Erste Schicht Kabel: \n Rot, Grün, Blau, Schwarz, Pink.");
                                                setTimeout(() => {
                                                    setMessages([
                                                        ...messages,
                                                        {
                                                            you: true,
                                                            message: input.split('\n').join('<br/>')
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Ok, ich probiere die beiden aus. Eine Sekunde."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Fantastische Arbeit. Es scheint, als hätten wir die Hauptplatine zerstört.<br/><br/>Zur letzten Komponente. Das muss der Strom sein."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Es scheint mehrere Schichten Kabel zu geben. Ich glaube, wir müssen das richtige Kabel in jeder Schicht durchtrennen, um den Strom und damit das ganze Gerät abzuschalten. Sagen Sie mir, welches Kabel ich durchtrennen soll..."
                                                        }
                                                    ]);
                                                    setStep(9);
                                                    setInputEnabled(true);
                                                }, delay);
                                            }, delay);
                                        }, delay);
                                    }, delay)
                                }, delay)
                            }, delay)
                        }, delay)
                    }
                } else {
                    if (commands.length !== 2) {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "Passen Sie auf, dass Sie Befehle ausprobieren, die die Maschine versteht.<br/><br/>Versuchen wir zwei andere Befehle."
                            }
                        ]);
                    } else {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "Diese Befehle habe ich ausprobiert, aber mindestens einer von ihnen ist falsch.<br/><br/>Passen Sie auf, dass Sie Befehle ausprobieren, die die Maschine versteht.<br/><br/><br/>Versuchen wir zwei andere Befehle."
                            }
                        ]);
                    }
                }
            }

            if (step === 9) {
                if (lowerInput === 'rot') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Erste Schicht Kabel: \n Rot, Grün, Blau, Schwarz, Pink.\n\n--- SCHICHT ERLEDIGT ---\n\n--- NÄCHSTEN SCHICHT ---");
                        setTimeout(() => {
                            setSystemInformation("Zweite Schicht Kabel: \n Pink, Gelb, Blau");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: "Das scheint richtig gewesen zu sein. Wir sind jetzt bei der nächsten Schicht. <br/><br/> Welches Kabel soll ich diesmal durchschneiden?"
                                }
                            ]);
                            setStep(10);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['grün', 'schwarz', 'blau', 'pink'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Erste Schicht Kabel: \n Rot, Grün, Blau, Schwarz, Pink.\n\n--- MANIPULATION ENTDECKT ---");
                        setTimeout(() => {
                            setSystemInformation("Erste Schicht Kabel: \n Rot, Grün, Blau, Schwarz, Pink.");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: "Oh nein, das scheint das falsche Kabel gewesen zu sein. Und die Zeitschaltuhr läuft jetzt schneller. Machen Sie es diesmal richtig.<br/><br/>Welches Kabel soll ich jetzt durchschneiden?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Das ist ja wohl keine Option! Konzentrieren Sie sich!`
                    }
                  ]);
                }
            }

            if (step === 10) {
                if (lowerInput === 'blau') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Zweite Schicht Kabel: \n Pink, Gelb, Blau\n\n--- SCHICHT ERLEDIGT ---\n\n--- NÄCHSTEN SCHICHT ---");
                        setTimeout(() => {
                            setSystemInformation("Dritte Schicht Kabel: \n Pink, Schwarz, Blau, Gelb");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: "Das scheint richtig gewesen zu sein. Wir sind jetzt bei der nächsten Schicht. <br/><br/> Welches Kabel soll ich diesmal durchschneiden?"
                                }
                            ]);
                            setStep(11);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['gelb', 'pink'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Zweite Schicht Kabel: \n Pink, Gelb, Blau\n\n--- MANIPULATION ENTDECKT ---");
                        setTimeout(() => {
                            setSystemInformation("Zweite Schicht Kabel: \n Pink, Gelb, Blau");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Das ist ja wohl keine Option! Konzentrieren Sie sich!`
                    }
                  ]);
                }
            }

            if (step === 11) {
                if (lowerInput === 'pink') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Dritte Schicht Kabel: \n Pink, Schwarz, Blau, Gelb\n\n--- SCHICHT ERLEDIGT ---\n\n--- NÄCHSTEN SCHICHT ---");
                        setTimeout(() => {
                            setSystemInformation("Vierte Schicht Kabel: \n Grün, Pink, Blau, Rot, Gelb");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: "Das scheint richtig gewesen zu sein. Wir sind jetzt bei der nächsten Schicht. <br/><br/> Welches Kabel soll ich diesmal durchschneiden?"
                                }
                            ]);
                            setStep(12);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['gelb', 'schwarz', 'blau'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Dritte Schicht Kabel: \n Pink, Schwarz, Blau, Gelb\n\n--- MANIPULATION ENTDECKT ---");
                        setTimeout(() => {
                            setSystemInformation("Dritte Schicht Kabel: \n Pink, Schwarz, Blau, Gelb");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Das ist ja wohl keine Option! Konzentrieren Sie sich!`
                    }
                  ]);
                }
            }

            if (step === 12) {
                if (lowerInput === 'blau') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setStopTimer(true);
                    setTimeout(() => {
                        setSystemInformation("Vierte Schicht Kabel: \n Grün, Pink, Blau, Rot, Gelb\n\n--- SCHICHT ERLEDIGT ---\n\n--- NÄCHSTEN SCHICHT ---");
                        setTimeout(() => {
                            setSystemInformation("--- Strom Abgeschaltet ---");
                            setImage(AllDisabled);
                            setTimeout(() => {
                                setSystemInformation(`--- ALLE SYSTEME ABGESCHALTET ---\n\n--- COUNTDOWN ANGEHALTEN BEI ${timerFrozen ? '60:00+' : calculateTimerString(timer, 2)} ---`);
                                setImage(Defused);
                                setTimeout(() => {
                                    if (!timerFrozen) {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                            },
                                            {
                                                you: false,
                                                message: `Fantastisch, wir haben alle Komponenten abgeschaltet. Gute Arbeit! Ganz Molvaria ist nun vor Yakovs furchtbaren Plänen sicher. Ok, ich glaube, wir haben noch genug Zeit, ihn zu finden. Während Sie das Gerät ausgeschaltet haben, habe ich einige Nachforschungen darüber angestellt, wo er sich aufhalten könnte. <a href='http://www.conspiralist.com/message-from-claire-de?playerTime=${ image === Defused ? '45:00 +' : elapsedTime(timer, 0)}'>Klicken Sie hier</a> um an die Informationen zu gelangen.`
                                            }
                                        ]);
                                    } else {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                            },
                                            {
                                                you: false,
                                                message: "Fantastisch, wir haben alle Komponenten abgeschaltet. Gute Arbeit! Ganz Molvaria ist nun vor Yakovs furchtbaren Plänen sicher. Leider glaube ich, dass es ihm gelungen ist zu entkommen, während wir das Gerät deaktiviert haben. Das ist aber ok. Wir haben seinen Plan trotzdem durchkreuzt. Als nächstes müssen wir dafür sorgen, dass die Leute erfahren, was die Regierung vorhatte. Während Sie dabei waren, das Gerät zu deaktivieren, habe ich mir einige Dinge überlegt.<a href='message-from-claire-2-de/?playerTime=60:00+'>Klicken Sie hier</a> um an die Informationen zu gelangen."
                                            }
                                        ]);
                                    }
                                }, delay);
                            }, delay);
                        }, delay);
                    }, delay);
                } else if (['rot', 'pink', 'grün', 'gelb'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Vierte Schicht Kabel: \n Grün, Pink, Blau, Rot, Gelb\n\n--- MANIPULATION ENTDECKT ---");
                        setTimeout(() => {
                            setSystemInformation("Vierte Schicht Kabel: \n Grün, Pink, Blau, Rot, Gelb");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, ich schneide das ${lowerInput} Kabel durch. Mal sehen...`
                                },
                                {
                                    you: false,
                                    message: "Oh nein, das scheint das falsche Kabel gewesen zu sein. Und die Zeitschaltuhr läuft jetzt schneller. Machen Sie es diesmal richtig.<br/><br/>Welches Kabel soll ich jetzt durchschneiden?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Das ist ja wohl keine Option! Konzentrieren Sie sich!`
                    }
                  ]);
                }
            }
        }, delay);
    }

    const TimerFunction = React.useCallback(() => {
        if (!stopTimer) {
            if ((timer - (1 + timePenalty)) < 1 && !timerFrozen) {
                console.log(timerFrozen);
                setTimerFrozen(true);
                setError(true);
            } else {
                timerDispatch();
                setTimePenalty(0);
            }
        }
    }, [stopTimer, timer, timerFrozen, timerDispatch, setError, timePenalty]);

    React.useEffect(() => {
        var clock = null;

        const resetTimeout = () => {
            clock = setTimeout(TimerFunction, timerInterval * 1000);
        }

        console.log('resetTimer');
        resetTimeout();

        return () => {
            clearTimeout(clock);
        }
    }, [timerInterval, TimerFunction, initialState]);

    React.useEffect(() => {
        document.querySelector('#chat-box').scrollTop = document.querySelector('#chat-box').scrollHeight;
    }, [ChatLog]);

    return (
        <div className="main-game">
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="left-panel">
                <Fieldset>
                    <div className="timer">
                        <p>Zeit bis zur Freigabe:</p>
                        <p style={{ color: timer < 300 ? 'red' : 'black' }}>{ timerFrozen ? 'FROZEN' : `${ timerValue }` }</p>
                    </div>
                </Fieldset>
                <Fieldset label="Systeminformation">
                    <TextField
                        value={ systemInformation }
                        placeholder='Warten auf Anweisungen...'
                        onChange={(e) => {}}
                        fullWidth
                        multiline
                        style={{ height: '100%', minHeight: 166 }}
                    />
                </Fieldset>

                <Fieldset label="Gerät Fehlersuche" style={{ maxHeight: 150 }}>
                    <div className="gif-panel">
                        <div className="content">
                            <img src={image} alt="" style={{ height: '100%', width: '100%' }}/>
                        </div>
                    </div>
                </Fieldset>
            </Panel>
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="right-panel">
                <Fieldset label="Z8 WTP Interner Chat:">
                    <div className="chat-section">
                        <TextField
                            value=""
                            placeholder='*** Nur für Mitarbeiter ***'
                            onChange={(e) => {}}
                            fullWidth
                            className="internal-use-box"
                        />
                        <div id="chat-box" className="gif-panel chatlog no-before" style={{ overflowY: 'scroll', height: `${ 330 - (25 * (rows - 1))}px`, scrollPaddingTop: '1rem' }}>
                            { ChatLog }
                        </div>
                        <Fieldset className="input-box">
                            <TextField
                                ref={userInput}
                                // value={inputText}
                                placeholder="Bitte tippen Sie hier..."
                                // onChange={debounce((e) => { setInputText(e.target.value); console.log('Changed', e.target.value) }, 1000)}
                                onKeyDown={ handleKeyDown }
                                enabled={ inputEnabled }
                                fullWidth
                                multiline={rows !== 1}
                                rows={rows}
                            />
                            <Button onClick={ sendMessage } disabled={!inputEnabled} style={{ height: '100%' }}>
                                Absenden
                            </Button>
                        </Fieldset>
                    </div>
                </Fieldset>
            </Panel>
        </div>
    )
}
