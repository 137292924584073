import React from 'react';
import {
    Button,
    Window as Windows95Window,
    WindowContent,
    WindowHeader
} from 'react95';

import Game from '../Game/Game';
import './window.scss';

export default function MainWindow ({ ...props })
{
    const [error, setError] = React.useState(false);
    const [shouldLoad, setShouldLoad] = React.useState(true);

    React.useEffect(() => {
        localStorage.setItem('shouldLoad', JSON.stringify(shouldLoad));
    }, [shouldLoad]);
    
    const handleReset = () => {
        setShouldLoad(false);
        window.location.reload();
    }

    return (
        <>
            <Windows95Window className="window">
                <WindowHeader active={!error} className="window-header">
                    <span>Z8 Water Treatment Plant Override</span>
                    <Button onClick={ () => { if (window.confirm('Are you sure you want to start again?')) { handleReset() } } }>
                        <span className="close-icon"/>
                    </Button>
                </WindowHeader>
                <WindowContent style={{ height: '520px'}}>
                    <Game setError={setError}/>
                </WindowContent>
            </Windows95Window>
            { error ?
                <Windows95Window className='window' style={{ position: 'fixed', top: '35%', right: 'calc(50% - 200px)', width: '400px', height: 'auto' }}>
                    <WindowHeader className='window-header'>
                        <span></span>
                        <Button onClick={ () => { setError(false) }}>
                            <span className='close-icon' />
                        </Button>
                    </WindowHeader>
                    <WindowContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ textAlign: 'center' }}>
                            Oh nein, wir scheinen keine Zeit mehr zu haben. Während Sie gearbeitet haben, ist es mir gelungen, den Timer des Gerätes zu hacken. Ich habe ihn für den Moment angehalten. Ich hoffe, ich kann ihn so lange anhalten, bis sie ihn komplett ausgeschaltet haben. Es könnte sein, dass uns Yakov deshalb entkommen wird, aber es geht um das Wohl der molvarischen Bevölkerung.
                        </p>
                        <Button onClick={ () => { setError(false) }} style={{ width: 'min-content', marginTop: '30px'}}>
                            Ok
                        </Button>
                    </WindowContent>
                </Windows95Window>
            : '' }
        </>
    )
}
